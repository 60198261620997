<template>
	<div class="stacked-bar-chart tw-w-full tw-h-[1000px] tw-mt-4 tw-bg-white tw-relative">
		<div
		v-for="i in 20"
		:key="'line-' + i"
		class="tw-w-full tw-h-[50px] tw-relative"
		>
			<div
			v-if="i > 0"
			class="tw-border-t tw-border-gray-300 tw-absolute tw-top-0 tw-w-full"
			/>

			<span class="tw-text-gray-600 tw-absolute tw-left-[150px] tw-top-0">
				{{ (i-1) * 50 }} mm
			</span>
			<span class="tw-text-gray-600 tw-absolute tw-right-2 tw-top-0">
				{{ (i-1) * 50 }} mm
			</span>
		</div>

		<div
		class="tw-bottom-0 tw-w-full tw-h-full tw-h-[50px] tw-absolute"
		>
			Interfaces : 
			<div
			v-for="state in (campaign.isDirMethodology ? dirStates : states)"
			:key="state"
			>
				<span :style="{ backgroundColor: getInterfaceColor(state), color: getInterfaceTextColor(state) }">
					{{ $t(state) }}
				</span>
			</div>
		</div>

		<div
		class="tw-flex tw-absolute tw-bottom-0 tw-w-full tw-h-full tw-flex-row"
		:style="{ transform: `translateX(${(gapCore / 2) + 240}px)`, gap: `${gapCore - 42}px` }"
		>
			<div
			v-for="(core, index) in cores"
			:key="'core-' + index"
			class="tw-flex tw-flex-col tw-items-center tw-mx-2"
			>
				<div
				v-for="(layer, layerIndex) in core.layersAndInterfaces"
				:key="'layer-' + index + '-' + layerIndex"
				:style="{
					height: layer.type === 'layer' ? `${layer.thickness}px` : `5px`,
					backgroundColor: layer.type === 'layer' ? layer.layerName.color ? layer.layerName.color : '#ccc' : getInterfaceColor(layer.state),
					width: layer.type === 'layer' ? '100%' : '120px !important',
					position: layer.type === 'layer' ? '' : 'relative',
					borderColor: layer.type === 'layer' ? 'black' : getInterfaceColor(layer.state) + '!important'
				}"
				class="tw-w-[100px] tw-border-[1px] tw-border-solid tw-border-[#2C0703]"
				:class="layer.type === 'layer' ? 'tw-w-[100px] tw-border-[1px] tw-border-solid tw-border-[#2C0703]' : 'tw-w-[100px] tw-border-[1px] tw-border-solid'"
				>
					&nbsp;
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "StackedBarChart",
	props: {
		cores: {
			type: Array,
			required: true
		},
		campaign: {
			type: Object,
			required: true
		},
		uniqueLayerNames: {
			type: Array,
			required: true
		}
	},
	data(){
		return {
			gapCore: 0,
			states: [
				"bonded",
				"semi-bonded",
				"unbonded"
			],
			dirStates: [
				"BACC",
				"MACC",
				"LFRA",
				"LFRA-RUPT",
				"DECO",
				"MADI"
			]
		};
	},
	watch: {
		cores(){
			this.calculateDisplay();
		},
		uniqueLayerNames: {
			handler(newLayerNames){
				if(!newLayerNames || !Array.isArray(newLayerNames)){
					console.warn("uniqueLayerNames is undefined or not an array");
					return;
				}
				this.updateCoresColors(newLayerNames);
			},
			deep: true
		}
	},
	methods: {
		updateCoresColors(updatedLayerNames){
			this.cores.forEach(core => {
				core.layersAndInterfaces.forEach(layer => {
					if(layer.type === "layer" && layer.layerName){
						const updatedLayer = updatedLayerNames.find(l => l.id === layer.layerName.id);
						if(updatedLayer){
							// Vérifiez que la propriété 'color' existe avant de l'appliquer
							if(updatedLayer.color){
								this.$set(layer.layerName, "color", updatedLayer.color);
							}
							else {
								console.warn(`Layer ${layer.layerName.name} is missing a color`);
							}
						}
					}
				});
			});
		},
		calculateDisplay(){
			const chart = document.querySelector(".stacked-bar-chart");
			if(!chart) return; // Si l'élément n'existe pas, retournez immédiatement.
			const totalWidth = chart.clientWidth - 200;
			if(totalWidth <= 0) return; // Évitez les calculs inutiles si la largeur est incorrecte.
			const widthPerBar = totalWidth / this.cores.length;
			this.gapCore = widthPerBar - 100;
		},
		getInterfaceColor(interfaceState){
			switch (interfaceState){
				case "bonded":
				case "BACC":
					return "lightgreen";
				case "semi-bonded":
				case "MACC" :
					return "yellow";
				case "LFRA":
					return "orange";
				case "LFRA-RUPT":
				case "unbonded":
					return "red";
				case "DECO":
					return "darkred";
				case "MADI":
					return "black";
			}
		},
		getInterfaceTextColor(interfaceState){
			switch (interfaceState){
				case "bonded":
				case "BACC":
				case "semi-bonded":
				case "MACC" :
				case "LFRA":
					return "black";
				case "LFRA-RUPT":
				case "unbonded":
				case "DECO":
				case "MADI":
					return "white";
			}
		}
	},
	mounted(){
		window.addEventListener("resize", this.calculateDisplay);
		this.$nextTick(() => {
			this.calculateDisplay();
		});
	},
	beforeDestroy(){
		window.removeEventListener("resize", this.calculateDisplay);
	}
};
</script>

<style scoped>
.stacked-bar-chart {
	background-color: white;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.stacked-bar-chart .tw-border-t {
	height: 1px;
	background-color: #ccc;
}

.stacked-bar-chart .tw-text-gray-600 {
	font-size: 12px;
}

.tw-border-gray-300 {
	border-color: #ccc;
}
</style>

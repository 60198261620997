<template>
	<div class="auscultation_map__menu tw-select-none">
		<v-expansion-panels v-model="isOpen">
			<v-expansion-panel>
				<v-expansion-panel-header class="pt-0 pb-0">
					<h3>Liste des couches</h3>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-radio-group 
					v-model="selected"
					>
						<v-radio
						v-for="item of items"
						v-if="!item.disabled"
						color="darkgrey"
						class="tw-m-0 tw-text-[12px] tw-p-0"
						:label="item.name"
						:value="item.id"
						danse
						hide-details
						:false-value="false"
						/>
					</v-radio-group>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
	name: "CoringMapMenu",
	data(){
		return {
			items: [],
			isOpen: 0,
			cores: []

		};
	},
	computed: {
		...mapGetters("coring", ["filter"]),
		
		selected: {
			set(arg){
				this.SET_FIlTER(arg);
			},
			get(){
				return this.filter;
			}
		}
	},
	methods: {
		...mapMutations("coring", ["SET_FIlTER"]),

		async fetchCores(){
			this.cores = await this.$api.cores.findByCampaign(this.$route.params.id);
		},

		setlayerList(){
			let coreResultType = {
				allResults: false,
				asbestos: false,
				asbestosDuplicated: false,
				PAH: false,
				pahDuplicated: false,
				TH: false
			};

			for(const {layersAndInterfaces: lai} of this.cores){
				for(let index = 0; index < lai.length; index += 2){
					if(lai[index].PAHResult !== null) coreResultType.PAH = true;
					if(lai[index].PAHResult !== null) coreResultType.pahDuplicated = true;
					if(lai[index].THResult !== null) coreResultType.TH = true;
					if(lai[index].asbestosResult !== null) coreResultType.asbestos = true;
					if(lai[index].asbestosResult !== null) coreResultType.asbestosDuplicated = true;
					if(lai[index].asbestosResult !== null || lai[index].PAHResult !== null || lai[index].THResult !== null) coreResultType.allResults = true;
				}
				if(coreResultType.asbestos === true && coreResultType.PAH === true && coreResultType.TH === true && coreResultType.allResults === true) break;
			}

			if(this.selected === false && coreResultType.asbestos === true){
				this.selected = "allResults";
			}
			
			let items = [];
			for(const [key, value] of Object.entries(coreResultType)){
				items.push({
					name: this.$t(key),
					disabled: !value,
					id: key,
				});
			}
			this.items = items;
		}
	},
	async mounted(){
		await this.fetchCores();
		this.setlayerList();
	}
};
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
	margin-top: 0px !important;
}
.v-input--radio-group--column .v-input--radio-group__input {
	gap: 5px !important;
}
.auscultation_map__menu {
  font-family: Roboto, sans-serif;
  .panel-header_overtext {
    padding: 16px 18px 10px 18px;
    font-size: 20px;
  }
  .v-expansion-panel-header {
    font-size: 15px;
  }
  .v-expansion-panel-content {
    &__wrap {
      max-height: 74vh;
      overflow-y: auto;
    }
  }
  .v-treeview-node {
    &__root {
      min-height: 30px;
    }
    &__children {
      min-height: 30px;
    }
  }
 min-width: 300px;
  .reset_button_container {
    display: flex;
    justify-content: center;
  }

  .miniCheckbox .mdi-checkbox-blank-outline::before,
  .miniCheckbox .mdi-checkbox-marked::before {
    transform: scale(0.8) !important;
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    background: #290703ee;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }
  
  .background {
    background-color: white;
  }
}
</style>

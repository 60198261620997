<template>
	<div class="tableContainer">
		<v-expansion-panels
		class="bordered"
		flat
		>
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="text-center">
						<h2 class="mb-2 mt-2">
							Résultats amiante
						</h2>
					</div>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-data-table
					class="bordered custom-table"
					flat
					:headers="eurofinsHeaders"
					:items="processedAsbestosData"
					:items-per-page="-1"
					hide-default-footer
					:sort-by="['businessId', 'coreLayerNumber']"
					:sort-desc="[false, false]"
					:item-class="itemBackground"
					>
						<!-- Template pour afficher les résultats de granulats -->
						<template
						v-slot:[`item.results.GRANULATS`]="{ item }"
						>
							<span v-if="item.results.GRANULATS.length !== 0">
								<v-chip
								:color="getColor(item.results.GRANULATS[0])"
								:text-color="item.results.GRANULATS[0].containsAsbestos === true || item.results.GRANULATS[0].containsAsbestos === null ? 'white':''"
								>
									{{ getText(item.results.GRANULATS[0], item.isAsbestosAnalyze) }} {{ item.results.GRANULATS[0].asbestosType !== null && item.results.GRANULATS[0].asbestosType !== '' ? ': ' + item.results.GRANULATS[0].asbestosType : '' }}
								</v-chip>
							</span>
						</template>
    
						<!-- Template pour afficher les résultats de liants -->
						<template
						v-slot:[`item.results.LIANT`]="{ item }"
						>
							<span v-if="item.results.LIANT.length !== 0">
								<v-chip
								:color="getColor(item.results.LIANT[0])"
								:text-color="item.results.LIANT[0].containsAsbestos || item.results.LIANT[0].containsAsbestos === null ? 'white':''"
								>
									{{ getText(item.results.LIANT[0], item.isAsbestosAnalyze) }}  {{ item.results.LIANT[0].asbestosType !== null ? ': ' + item.results.LIANT[0].asbestosType : '' }}
								</v-chip>
							</span>
						</template>
					</v-data-table>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
export default {
	name: "AmianteTable",
	props: {
		asbestosTableData: {
			type: Array,
			required: true,
		},
		campaign: {
			required: true
		}
	},
	data(){
		return {
			granulats: {},
      		liants: {},
			eurofinsHeaders: [
				{
					text: "N° carotte",
					align: "center",
					sortable: true,
					value: "core.number",
				},
				{
					text: "Carotte (Ref.)",
					align: "center",
					sortable: true,
					value: "core.customerId",
				},
				{
					text: "Réf. bureau d'étude",
					align: "center",
					sortable: true,
					value: "businessOrDisplay",
					width: "100px"
				},
				{
					text: "Ouvrage",
					align: "center",
					sortable: true,
					value: "core.road.name",
				},
				{
					text: "PR",
					align: "center",
					sortable: true,
					value: "core.landmark",
				},
				{
					text: "Abs",
					align: "center",
					sortable: true,
					value: "core.abscissa",
				},
				{
					text: "Sens",
					align: "center",
					sortable: true,
					value: "core.direction",
				},
				{
					text: "Voie",
					align: "center",
					sortable: true,
					value: "core.lane",
				},
				{
					text: "Latéralisation",
					align: "center",
					sortable: true,
					value: "core.lateralPosition",
				},
				{
					text: "Couche",
					align: "center",
					sortable: true,
					value: "number",
				},
				{
					text: "Matériau",
					align: "center",
					sortable: true,
					value: "materialName",
				},
				{
					text: "Épaisseur",
					align: "center",
					sortable: true,
					value: "thickness",
				},
				{
					text: "Date prélèvement",
					align: "center",
					sortable: true,
					value: "core.extractedAt",
				},
				{
					text: "Date analyse",
					align: "center",
					sortable: true,
					value: "results.GRANULATS[0].receivedDate",
				},
				{
					text: "Groupement",
					align: "center",
					sortable: true,
					value: "providerGroupementNumberAsbestos"
				},
				{
					text: "Analyses Granulats",
					align: "center",
					sortable: false,
					value: "results.GRANULATS"
				},
				{
					text: "Analyses Liant",
					align: "center",
					sortable: false,
					value: "results.LIANT"
				},
				{
					text: "Réf. laboratoire",
					align: "center",
					sortable: true,
					value: "results.GRANULATS[0].labRef",
					width: "200px"
				},
				{
					text: "Réf. MAORIE",
					align: "center",
					sortable: false,
					value: "businessId",
					width: "200px"
				},
			]
		};
	},
	computed: {
		processedAsbestosData(){
			return this.asbestosTableData.map((item, index, array) => {
				const currentCoreNumber = item.core.number;
				const previousCoreNumber = index > 0 ? array[index - 1].core.number : null;
				const nextCoreNumber = index < array.length - 1 ? array[index + 1].core.number : null;

				const isFirstLayer = index === 0 || previousCoreNumber !== currentCoreNumber;
				const isLastLayer = index === array.length - 1 || nextCoreNumber !== currentCoreNumber;

				const granulats = [];
				const liant = [];
				item.results.ASBESTOS.forEach((result) => {
					if(result.fractionType === "GRANULATS"){
						granulats.push(result);
					}
					else if(result.fractionType === "LIANT"){
						liant.push(result);
					}
				});
				return {
					...item,
					isFirstLayer,
					isLastLayer,
					results: {
						GRANULATS: granulats,
						LIANT: liant,
					},
				};
			});
		},
	},
	methods: {
		itemBackground(item){
			const isEven = item.core.number % 2 === 0;
			return {
				"grey lighten-3": isEven,
				white: !isEven,
				"first-layer-border": item.isFirstLayer,
				"last-layer-border": item.isLastLayer,
				"core-border": true,
			};
		},
		getColor(asbestosResult){
			if(null == asbestosResult && isAsbestosAnalyze) return "#333";
			if(null == asbestosResult && !isAsbestosAnalyze) return "#222222";
			if(asbestosResult.containsAsbestos === null) return "#fefefe";
			if(true === asbestosResult.containsAsbestos) return "#ff0d0d";
			return "#69b34c";
		},
		getText(asbestosResult, isAsbestosAnalyze){
			if(null == asbestosResult && isAsbestosAnalyze) return "En attente de résultat";
			if(null == asbestosResult && !isAsbestosAnalyze) return "Expertise non demandée";
			if(asbestosResult.containsAsbestos === null) return "Pas assez de matière";
			if(true === asbestosResult.containsAsbestos) return "Détecté";
			else return "Non détecté";
		},
	}
};
</script>

<style lang="scss" scoped>
.tableContainer {
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }

  .custom-table {
    ::v-deep thead th {
      font-weight: bold !important;
      font-size: 18px !important;
      color: black !important;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }

    ::v-deep tbody tr.core-border > td:first-child {
      border-left: 4px solid black !important;
    }

	::v-deep tbody tr.core-border > td:last-child {
      border-right: 4px solid black !important;
    }

    ::v-deep tbody tr.first-layer-border > td {
      border-top: 4px solid black !important;
    }

    ::v-deep tbody tr.last-layer-border > td {
      border-bottom: 4px solid black !important;
    }

    ::v-deep tbody tr.first-layer-border > td:first-child {
      border-top-left-radius: 4px !important;
    }
    ::v-deep tbody tr.first-layer-border > td:last-child {
      border-top-right-radius: 4px !important;
    }

    ::v-deep tbody tr.last-layer-border > td:first-child {
      border-bottom-left-radius: 4px !important;
    }
    ::v-deep tbody tr.last-layer-border > td:last-child {
      border-bottom-right-radius: 4px !important;
    }
  }
}
</style>

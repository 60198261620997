<template>
	<div class="core-pictures tw-w-full tw-h-[375px] tw-mt-4 tw-bg-white tw-relative">
		<div
		class="tw-flex tw-absolute tw-bottom-0 tw-w-full tw-h-full tw-flex-row"
		:style="{ transform: `translateX(${(gapCore / 2) + 180}px)`, gap: `${gapCore - 65}px` }"
		>
			<div
			v-for="(core, index) in cores"
			:key="'core-' + index"
			class="tw-flex tw-flex-col tw-items-center tw-mx-2"
			>
				<div class="tw-mb-2 tw-text-center">
					<!-- Afficher le nom ou un identifiant pour chaque core -->
					<span class="tw-font-bold">
						{{ core.displayNamePrefix.name + core.displayName }}
					</span>
				</div>
  
				<div>
					<img
					:src="coreImageUrls[index]"
					alt="Core Picture"
					class="tw-w-[100px] tw-h-auto tw-border tw-border-gray-300"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
  

<script>
export default {
	name: "CorePicturesDiv",
	props: {
		cores: {
			type: Array,
			required: true
		}
	},
	data(){
		return {
			gapCore: 0,
			coreImageUrls: []
		};
	},
	watch: {
		cores(){
			this.calculateDisplay();
			this.loadCorePictures();
		}
	},
	methods: {
		calculateDisplay(){
			const chart = document.querySelector(".stacked-bar-chart");
			if(!chart) return;
			let totalWidth = chart.clientWidth - 200;
			let widthPerBar = (totalWidth / this.cores.length);
			this.gapCore = widthPerBar - 100;
		},
		async loadCorePictures(){
			this.coreImageUrls = await Promise.all(
				this.cores.map(async(core) => {
					const corePicture = core.corePictures.find(picture => picture.type === "core");
					if(corePicture){
						// Appel à l'API pour récupérer l'image
						const blob = await this.$api.corePictures.downloadPictureById(corePicture.id);
						if(!blob){
							console.error("Erreur : Blob non récupéré");
							return null;
						}
						return URL.createObjectURL(blob); // Création d'une URL utilisable par le front
					}
					return null;
				})
			);
		}


	},
	mounted(){
		this.loadCorePictures();
		window.addEventListener("resize", this.calculateDisplay);
		this.$nextTick(() => {
			this.calculateDisplay();
		});
	},
	beforeDestroy(){
		window.removeEventListener("resize", this.calculateDisplay);
	}
};
</script>

<style scoped>
.stacked-bar-chart {
	background-color: white;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.stacked-bar-chart .tw-border-t {
	height: 1px;
	background-color: #ccc;
}

.stacked-bar-chart .tw-text-gray-600 {
	font-size: 12px;
}

.tw-border-gray-300 {
	border-color: #ccc;
}
</style>

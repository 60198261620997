<template>
	<div
	v-if="showCore === true"
	@click="clicked"
	style="border: none !important"
	:style="!filter ? { '--bg-color': color, 'color': textColor, 'margin-left': '10px', border: ' 1px solid black' } : { 'margin-right': '0px'}"
	class="tw-flex tw-flex-col"
	:class="{
		'move': movingCore === core.id,
		'select': editingCore.id === core.id,
		'rmMarginTop': filter === 'asbestosDuplicated' || filter === 'pahDuplicated',
		'tw-w-[500px]': filter === 'asbestosDuplicated' || filter === 'pahDuplicated',
		'tw-mr-[10px]': filter !== 'asbestosDuplicated' && filter !== 'pahDuplicated'
	}"
	coring-map-marker
	>
		<div class="marker-container">
			<span
			v-if="filter === 'asbestosDuplicated' || filter === 'pahDuplicated'"
			class="coreName coreLandmark"
			>
				{{ core.displayName ?
					(
						core.displayNamePrefix.name + core.displayName === core.businessId ?
							core.businessId.substr(core.businessId.length - 6) : 
							core.displayNamePrefix.name + core.displayName 
					) : 
					core.businessId.substr(core.businessId.length - 6) }}
			</span>

			<span
			v-if="filter === 'allResults' && core.landmark !== null"
			class="coreName coreLandmark"
			>
				{{ core.abscissa !== undefined ? 'PR ' + core.landmark + ' + ' + core.abscissa + ' Sens '+ core.direction + ' - Voie ' + core.lane : 'PR '+ core.landmark + ' Sens '+ core.direction + ' - Voie ' + core.lane }}
			</span>

			<img
			v-if="filter"
			:src="getBackgroundImage()"
			style="width: 30px; height: 30px;"
			/>
			<span class="centered">
				{{ core.displayName ? core.displayName.replace(/^0+/, '') : core.number.replace(/^0+/, '') }}
			</span>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import coring from "../../utils/coring";
import {mapGetters, mapMutations, mapActions} from "vuex";
import mapboxgl from "mapbox-gl";
import popupUtils from "../../utils/map/popup.utils";
import CoringMapPopup from "./Coring.map.popup";

export default Vue.extend({
	name: "CoringMapMarker",
	components: {
		
	},
	props: {
		core: {
			default: false
		},
	},
	data(){
		return {
			clickCount: 0,
		};
	},
	computed: {
		...mapGetters("coring", [
			"editingCore", "map", "movingCore", "campaign", "filter", "filterValue", "filterByValues"
		]),

		color(){
			if(this.core.status !== "results_acquired" || !this.filter){
				if(this.campaign.status === "awaiting_validation"){
					if(this.campaign.shouldValidateCustomer){
						if(this.core.isValidatedCustomer === true) return "green";
						if(this.core.isValidatedCustomer === false) return "red";
					}
					if(this.core.status === "waiting") return "green";
					if(this.core.status === "validated") return "green";
					if(this.core.status === "empty") return "orange";
					if(this.core.status === "cancelled") return "red";
				}
				else if(this.campaign.status === "programing_validated"){
					if(this.core.status === "waiting") return "orange";
					if(this.core.status === "validated") return "green";
					if(this.core.status === "sent_to_study_manager") return "green";
					if(this.core.status === "cancelled") return "red";
				}
				else if(this.campaign.status === "taken"){
					if(this.core.status === "validated") return "green";
					if(this.core.status === "sent_to_study_manager") return "orange";
					if(this.core.status === "cancelled") return "red";
				}
				else if(this.campaign.status === "taking_validated"){
					if(this.core.status === "results_obtained") return "green";
					if(this.core.status === "validated") return "orange";
					if(this.core.status === "sent_for_analysis") return "green";
					if(this.core.status === "cancelled") return "red";
				}

				else if(this.core.status === "cancelled") return "#2B0803";
				return "#D8D8D8";
			}
			
			else if(this.filter === "PAH" || this.filter === "pahDuplicated"){
				if(this.core.status === "cancelled") return "#2B0803";
				if(this.sum >= 1000) return "black";
				else if(this.sum >= 500 && this.sum < 1000) return "red";
				else if(this.sum >= 50 && this.sum < 500) return "orange";
				else if(this.sum < 50) return "green";
			}
			else if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
				if(this.core.status === "cancelled") return "#2B0803";
				if(this.asbestos === true) return "red";
				else return "green";
			}
			else if(this.filter === "TH"){
				if(this.sum >= 500) return "orange";
				if(this.core.status === "cancelled") return "#2B0803";
				else if(this.sum < 500) return "green";
			}
			else if(this.filter === "allResults"){
				if(this.core.status === "cancelled") return "#2B0803";
				return null;
			}
		},

		textColor(){
			let textColor;
			this.color === "#D8D8D8" ? textColor = "black" : textColor = "white";
			return textColor;
		},

		border(){
			let border;
			this.color === "#D8D8D8" ? border = "solid black 2px" : border = null;
			return border;
		},

		sum(){
			let sum = null;
			for(const layer of this.core.layersAndInterfaces){
				if(this.filter === "PAH" || this.filter === "pahDuplicated"){
					if(layer.type !== "layer") continue;
					if((sum === null || sum < layer.PAHResult?.sum) && layer.PAHResult?.sum !== undefined && layer.PAHResult !== null) sum = layer.PAHResult?.sum ?? null;
				}
				else if(this.filter === "TH"){
					if(layer.type !== "layer") continue;
					if(layer.THResult?.sum === undefined && layer.THResult === null) return null;
					if((sum === null || sum < layer.THResult?.sum) && layer.THResult?.sum !== undefined && layer.THResult !== null) sum = layer.THResult?.sum ?? null;
				}
			}
			return sum;
		},
		PAHSum(){
			let sum = null;
			if(this.core.layersAndInterfaces !== undefined){
				for(const layer of this.core.layersAndInterfaces){
					if(layer.type !== "layer") continue;
					if((sum === null || sum < layer.PAHResult?.sum) && layer.PAHResult?.sum !== undefined && layer.PAHResult !== null) sum = layer.PAHResult.sum;
				}
			}
			return sum;
		},
		THSum(){
			let sum = null;
			if(this.core.layersAndInterfaces !== undefined){
				for(const layer of this.core.layersAndInterfaces){
					if(layer.type !== "layer") continue;
					sum = layer.THResult?.sum ?? null;
				}
			}
			return sum;
		},
		asbestos(){
			let containsAsbestos = null;
			if(this.core.layersAndInterfaces !== undefined){
				for(const layer of this.core.layersAndInterfaces){
					if(layer.asbestosResult?.containsAsbestos === true) containsAsbestos = true;
					if(layer.asbestosResult?.containsAsbestos === false && containsAsbestos !== true) containsAsbestos = false;
				}
			}
			return containsAsbestos;
		},

		showCore(){
			if(this.filter === "PAH" || this.filter === "pahDuplicated"){
				if(this.sum >= 1000 && this.filterValue.PAH.indexOf(1000) === -1) return false;
				else if(this.sum >= 500 && this.sum < 1000 && this.filterValue.PAH.indexOf(500) === -1) return false;
				else if(this.sum >= 50 && this.sum < 500 && this.filterValue.PAH.indexOf(50) === -1) return false;
				else if(this.sum < 50 && this.sum !== null && this.filterValue.PAH.indexOf(0) === -1) return false;
				else if(this.sum === null && this.filterValue.PAH.indexOf("waiting") === -1 && this.core.status === "waiting") return false;
				else if(this.sum === null && this.filterValue.PAH.indexOf("cancelled") === -1 && this.core.status === "cancelled") return false;
				else if(this.sum === null && this.filterValue.PAH.indexOf("empty") === -1 && this.core.status === "empty") return false;
				else if(this.sum === null && this.filterValue.PAH.indexOf(null) === -1 && this.core.status !== "empty" && this.core.status !== "waiting" && this.core.status !== "cancelled") return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
				if(this.asbestos === true && this.filterValue.asbestos.indexOf(true) === -1) return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf("waiting") === -1 && this.core.status === "waiting") return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf("cancelled") === -1 && this.core.status === "cancelled") return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf("empty") === -1 && this.core.status === "empty") return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf(null) === -1 && this.core.status !== "empty" && this.core.status !== "waiting" && this.core.status !== "cancelled") return false;
				else if(this.asbestos === false && this.filterValue.asbestos.indexOf(false) === -1) return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else if(this.filter === "TH"){
				if(this.sum >= 500 && this.filterValue.TH.indexOf(500) === -1 && this.sum !== null) return false;
				else if(this.sum < 500 && this.filterValue.TH.indexOf(0) === -1 && this.sum !== null) return false;
				else if(this.sum === null && this.filterValue.TH.indexOf("waiting") === -1 && this.core.status === "waiting") return false;
				else if(this.sum === null && this.filterValue.TH.indexOf("cancelled") === -1 && this.core.status === "cancelled") return false;
				else if(this.sum === null && this.filterValue.TH.indexOf("empty") === -1 && this.core.status === "empty") return false;
				else if(this.sum === null && this.filterValue.TH.indexOf(null) === -1 && this.core.status !== "empty" && this.core.status !== "waiting" && this.core.status !== "cancelled") return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else if(this.filter === "allResults"){
				if(this.PAHSum >= 1000 && this.filterValue.PAH.indexOf(1000) === -1 && this.PAHSum !== null) return false;
				else if(this.PAHSum >= 500 && this.PAHSum < 1000 && this.filterValue.PAH.indexOf(500) === -1 && this.PAHSum !== null) return false;
				else if(this.PAHSum >= 50 && this.PAHSum < 500 && this.filterValue.PAH.indexOf(50) === -1 && this.PAHSum !== null) return false;
				else if(this.PAHSum < 50 && this.filterValue.PAH.indexOf(0) === -1 && this.PAHSum !== null) return false;
				
				else if(this.asbestos === true && this.filterValue.asbestos.indexOf(true) === -1) return false;
				else if(this.asbestos === false && this.filterValue.asbestos.indexOf(false) === -1) return false;
				else if(this.THSum >= 500 && this.filterValue.TH.indexOf(500) === -1 && this.THSum !== null) return false;
				else if(this.THSum < 500 && this.filterValue.TH.indexOf(0) === -1 && this.THSum !== null) return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else {
				return true;
			}

		}
	},
	methods: {
		...mapMutations("coring", [
			"START_EDITING_CORE", "START_MOVING_CORE", "STOP_MOVING_CORE"
		]),
		...mapActions("coring", ["updateCore"]),

		newFilter(){
			let display = true;
			this.filterByValues.forEach(filter => {
				if(filter.type === "road"){
					if(!filter.value.includes(this.core.road.name)){
						display = false;
					}
				}
				else if(filter.type === "business"){
					if(this.core.displayNamePrefix !== null && this.core.displayName !== null){
						if(!filter.value.includes(this.core.displayNamePrefix.name + this.core.displayName)){
							display = false;
						}
					}
					else {
						if(!filter.value.includes(this.core.businessId)){
							display = false;
						}
					}
				}
				else if(!filter.value.includes(this.core[filter.type])){
					display = false;
				}
			});
			return display;
		},
		clicked(){
			this.clickCount++;
			if(this.clickCount !== 1) return;
			setTimeout(() => {
				if(this.clickCount > 1){
					if(this.movingCore === false && (this.campaign.status === "awaiting_validation" || this.campaign.status === "programing_validated" || this.campaign.status === "taken")){
						this.START_MOVING_CORE(this.core.id);
						setTimeout(this.moveMarker, 100);
					}
				}
				else {
					this.START_EDITING_CORE(this.core);
					if(this.campaign.status === "results_obtained" || this.campaign.status === "sent_for_analysis" || (this.campaign.status === "status_archived" && this.filter)){
						this.displayPopup();
					}
					
				}

				this.clickCount = 0;
			}, 200);
		},

		moveMarker(){
			this.map.on("click", this.moveMarkerEventClick);
			this.map.getCanvas().onmouseleave = this.moveMarkerStop;
			this.map.getCanvas().style.cursor = "crosshair";
		},

		moveMarkerEventClick(e){
			this.moveMarkerStop();
			this.updateCore({...this.core, lat: e.lngLat.lat, lng: e.lngLat.lng});
		},

		moveMarkerStop(){
			this.map.off("click", this.moveMarkerEventClick);
			this.map.getCanvas().onmouseleave = () => {};
			this.map.getCanvas().style.cursor = "";
			this.STOP_MOVING_CORE();
		},

		async displayPopup(){

			let data = await this.$api.cores.get(this.core.id);

			this.popup = popupUtils.generatePopup(
				this.core.lng,
				this.core.lat,
				data,
				this.map,
				CoringMapPopup,
				() => {
					this.popup = null;
				}
			);
			this.$emit("popupOpened", this.core);
		},

		getBackgroundImage(){
			let imageName;
			let checkNumber = 0;
			let sumAsbestos = 0;
			let sumPAH = 0;
			let sumTH = 0;
			if(this.filter === "allResults"){
				this.campaign.hasAsbestos === true ? checkNumber += 1 : "";
				this.campaign.hasTH === true ? checkNumber += 1 : "";
				this.campaign.hasPAH === true ? checkNumber += 1 : "";
			}
			else {
				checkNumber = 1;
			}
			
			switch (checkNumber){
				case 1: 
					imageName = "circle";
					break;
				case 2: 
					imageName = "half_circle";
					break;
				case 3: 
					imageName = "third_circle";
					break;
			}

			if(this.filter === "allResults"){

				if(checkNumber === 1){
					if(this.campaign.hasAsbestos === true){
						imageName += "_A";
					}
					else if(this.campaign.hasTH === true){
						imageName += "_HCT";
					}
					else if(this.campaign.hasPAH === true){
						imageName += "_HAP";
					}
						
				}
			}
			else {
				if(checkNumber === 1){
					if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
						imageName += "_A";
					}
					else if(this.filter === "TH"){
						imageName += "_HCT";
					}
					else if(this.filter === "PAH" || this.filter === "pahDuplicated"){
						imageName += "_HAP";
					}
						
				}
			}

			if(checkNumber === 2){
				if(this.campaign.hasAsbestos === true && this.campaign.hasPAH === true){
					imageName += "_AHAP";
				}
				else if(this.campaign.hasAsbestos === true && this.campaign.hasTH === true){
					imageName += "_AHCT";
				}
				else if(this.campaign.hasPAH === true && this.campaign.hasTH === true){
					imageName += "_HAPHCT";
				}
					
			}
			
			for(const layer of this.core.layersAndInterfaces){
				if(layer.type !== "layer") continue;
				if(this.campaign.hasAsbestos){
					if(layer.asbestosResult?.containsAsbestos === true){
						sumAsbestos = 2;
					}
					else if(layer.asbestosResult?.containsAsbestos === false &&  sumAsbestos <= 1){
						sumAsbestos = 1;
					}
				}
				if(this.campaign.hasPAH){
					if(layer.PAHResult?.sum >= 1000){
						sumPAH = 4;
					}
					else if(layer.PAHResult?.sum >= 500 && sumPAH <= 3){
						sumPAH = 3;
					}
					else if(layer.PAHResult?.sum >= 50 && sumPAH <= 2){
						sumPAH = 2;
					}
					else if(layer.PAHResult?.sum < 50 && sumPAH <= 1){
						sumPAH = 1;
					}
				}
				if(this.campaign.hasTH){
					if(layer.THResult?.sum >= 500 && sumTH <= 2){
						sumTH = 2;
					}
					else if(layer.THResult?.sum < 500 && sumTH <= 1){
						sumTH = 1;
					}
				}
					
			}
			if(this.filter === "allResults"){
				if(checkNumber === 1){
					if(this.campaign.hasAsbestos === true){
						imageName += `${"_" + sumAsbestos}`;
					}
					else if(this.campaign.hasTH === true){
						
						imageName += `${"_" + sumTH}`;
					}
					else if(this.campaign.hasPAH === true){
						
						imageName += `${"_" + sumPAH}`;
					}
						
				}
			}
			else {
				if(checkNumber === 1){
					if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
						imageName += `${"_" + sumAsbestos}`;
					}
					else if(this.filter === "TH"){
						
						imageName += `${"_" + sumTH}`;
					}
					else if(this.filter === "PAH" || this.filter === "pahDuplicated"){
						
						imageName += `${"_" + sumPAH}`;
					}
						
				}
			}
			if(checkNumber === 2){
				if(this.campaign.hasAsbestos === true && this.campaign.hasPAH === true){
					imageName += `${"_" + sumAsbestos}${"_" + sumPAH}`;
				}
				else if(this.campaign.hasAsbestos === true && this.campaign.hasTH === true){
					
					imageName += `${"_" + sumAsbestos}${"_" + sumTH}`;
				}
				else if(this.campaign.hasPAH === true && this.campaign.hasTH === true){
					
					imageName += `${"_" + sumPAH}${"_" + sumTH}`;
				}
					
			}
			else if(checkNumber === 3){
				imageName += `${"_" + sumAsbestos}${"_" + sumPAH}${"_" + sumTH}`;
			}

			return require(`@/assets/images/coring/Markers/${imageName}.png`);
		}
	},
});
</script>

<style lang="scss">
.rmMarginTop {
	margin-top: -23px;
}
.container {
  position: relative;
  text-align: center;
  color: white;
}

.coreName {
	color: black !important;
	background-color: white !important;
	padding: 5px !important;
	border-radius: 8px !important;
	white-space: nowrap;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  pointer-events: none;
}

.coreLandmark {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  white-space: nowrap;
  z-index: 10;
}

.marker-container {
  position: relative;
  display: inline-block;
  text-align: center;
  width: fit-content;
}

div[coring-map-marker]{
    color: white;
    background: var(--bg-color);
    aspect-ratio: 1/1;
    height: 20px;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    cursor: pointer;

    &.move{
        animation: move 500ms linear infinite alternate;
    }

    &.select{
        border: solid black 2px;
    }
}

@keyframes move{
    from{
        background: black;
    }

    to{
        background: orange;
    }
}
</style>

<template>
	<div
	class="tw-bg-[white] tw-p-[10px] tw-absolute tw-top-[10px] tw-right-[15px] tw-rounded-[4px]"
	v-if="filter"
	>
		<div v-if="filter === 'asbestos' || filter === 'asbestosDuplicated'">
			<h3>Amiante</h3>

			<VCheckbox
			color="darkgrey"
			class="tw-m-0 tw-text-[12px] tw-p-0 tw-scale-[0.8]"
			v-model="asbestosValue"
			danse
			hide-details
			v-for="h of asbestos"
			:value="h.value"
			>
				<template v-slot:label>
					<div class="tw-flex tw-gap-[10px] tw-items-center">
						<div
						class="tw-rounded-full tw-h-[15px] tw-w-[15px]"
						:style="{background: h.color}"
						/>

						<span>{{ h.label }}</span>
					</div>
				</template>
			</VCheckbox>
		</div>

		<div
		v-if="filter === 'PAH' || filter === 'pahDuplicated'"
		class="tw-flex tw-flex-col tw-gap-[5px]"
		>
			<h3>HAP</h3>

			<VCheckbox
			color="darkgrey"
			class="tw-m-0 tw-text-[12px] tw-p-0 tw-scale-[0.8]"
			v-model="PAHValue"
			danse
			hide-details
			v-for="h of pah"
			:value="h.value"
			>
				<template v-slot:label>
					<div class="tw-flex tw-gap-[10px] tw-items-center">
						<div
						class="tw-rounded-full tw-h-[15px] tw-w-[15px]"
						:style="{background: h.color}"
						/>

						<span>{{ h.label }}</span>
					</div>
				</template>
			</VCheckbox>
		</div>

		<div v-if="filter === 'TH'">
			<h3>HCT</h3>

			<VCheckbox
			color="darkgrey"
			class="tw-m-0 tw-text-[12px] tw-p-0 tw-scale-[0.8]"
			v-model="THValue"
			danse
			hide-details
			v-for="h of th"
			:value="h.value"
			>
				<template v-slot:label>
					<div class="tw-flex tw-gap-[10px] tw-items-center">
						<div
						class="tw-rounded-full tw-h-[15px] tw-w-[15px]"
						:style="{background: h.color}"
						/>

						<span>{{ h.label }}</span>
					</div>
				</template>
			</VCheckbox>
		</div>

		<div v-if="filter === 'allResults'">
			<h3>Amiante</h3>

			<VCheckbox
			color="darkgrey"
			class="tw-m-0 tw-text-[12px] tw-p-0 tw-scale-[0.8]"
			v-model="asbestosValue"
			danse
			hide-details
			v-for="h of asbestos"
			:value="h.value"
			>
				<template v-slot:label>
					<div class="tw-flex tw-gap-[10px] tw-items-center">
						<div
						class="tw-rounded-full tw-h-[15px] tw-w-[15px]"
						:style="{background: h.color}"
						/>

						<span>{{ h.label }}</span>
					</div>
				</template>
			</VCheckbox>

			<h3>HAP</h3>

			<VCheckbox
			color="darkgrey"
			class="tw-m-0 tw-text-[12px] tw-p-0 tw-scale-[0.8]"
			v-model="PAHValue"
			danse
			hide-details
			v-for="h of pah"
			:value="h.value"
			>
				<template v-slot:label>
					<div class="tw-flex tw-gap-[10px] tw-items-center">
						<div
						class="tw-rounded-full tw-h-[15px] tw-w-[15px]"
						:style="{background: h.color}"
						/>

						<span>{{ h.label }}</span>
					</div>
				</template>
			</VCheckbox>


			<h3>HCT</h3>

			<VCheckbox
			color="darkgrey"
			class="tw-m-0 tw-text-[12px] tw-p-0 tw-scale-[0.8]"
			v-model="THValue"
			danse
			hide-details
			v-for="h of th"
			:value="h.value"
			>
				<template v-slot:label>
					<div class="tw-flex tw-gap-[10px] tw-items-center">
						<div
						class="tw-rounded-full tw-h-[15px] tw-w-[15px]"
						:style="{background: h.color}"
						/>

						<span>{{ h.label }}</span>
					</div>
				</template>
			</VCheckbox>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
	name: "MapFilters",
	props: {

	},
	data(){
		return {
			pah: [
				{
					label: "HAP ≥ 1000 mg/kg",
					value: 1000,
					color: "black"
				},
				{
					label: "500 ≤ HAP < 1000 mg/kg",
					value: 500,
					color: "red"
				},
				{
					label: "50 ≤ HAP < 500 mg/kg",
					value: 50,
					color: "orange"
				},
				{
					label: "HAP < 50 mg/kg",
					value: 0,
					color: "green"
				},
				{
					label: "Pas d'expertise",
					value: null,
					color: "grey"
				},
				{
					label: "Non prélevée",
					value: "empty",
					color: "grey"
				},
				{
					label: "En attente",
					value: "waiting",
					color: "grey"
				},
				{
					label: "Annulé",
					value: "cancelled",
					color: "grey"
				}
			],
			asbestos: [
				{
					label: "Présence",
					value: true,
					color: "red"
				},
				{
					label: "Non présence",
					value: false,
					color: "green"
				},
				{
					label: "Pas d'expertise",
					value: null,
					color: "grey"
				},
				{
					label: "Non prélevée",
					value: "empty",
					color: "grey"
				},
				{
					label: "En attente",
					value: "waiting",
					color: "grey"
				},
				{
					label: "Annulé",
					value: "cancelled",
					color: "grey"
				}
			],
			th: [
				{
					label: "HCT ≥ 500 mg/kg",
					value: 500,
					color: "orange"
				},
				{
					label: "HCT < 500 mg/kg",
					value: 0,
					color: "green"
				},
				{
					label: "Pas d'expertise",
					value: null,
					color: "grey"
				},
				{
					label: "Non prélevée",
					value: "empty",
					color: "grey"
				},
				{
					label: "En attente",
					value: "waiting",
					color: "grey"
				},
				{
					label: "Annulé",
					value: "cancelled",
					color: "grey"
				}	
			]
		};
	},
	computed: {
		...mapGetters("coring", ["filter", "filterValue"]),

		PAHValue: {
			set(value){
				this.SET_FIlTER_PAH(value);

			},
			get(){
				return this.filterValue.PAH;
			}
		},

		asbestosValue: {
			set(value){
				this.SET_FIlTER_ASBESTOS(value);

			},
			get(){
				return this.filterValue.asbestos;
			}
		},

		THValue: {
			set(value){
				this.SET_FIlTER_TH(value);

			},
			get(){
				return this.filterValue.TH;
			}
		}
		
	},
	watch: {
		
	},
	methods: {
		...mapMutations("coring", [
			"SET_FIlTER_PAH", "SET_FIlTER_TH", "SET_FIlTER_ASBESTOS"
		])
	},
	mounted(){
		this.SET_FIlTER_PAH([
			1000, 500, 50, 0, null, "waiting", "cancelled", "empty"
		]);
		this.SET_FIlTER_ASBESTOS([
			true, false, null, "waiting", "cancelled", "empty"
		]);
		this.SET_FIlTER_TH([
			500, 0, null, "waiting", "cancelled", "empty"
		]);
	}
};
</script>

<style lang="scss">

</style>

<template>
	<section class="structuralParametersModal tw-flex tw-flex-col tw-h-full">
		<v-card
		flat
		outlined
		class="structuralParametersModal__card tw-flex tw-flex-col tw-h-full"
		id="modale"
		>
			<v-card-title class="center justify-center">
				<h3>Paramétrer les noms des couches</h3>
			</v-card-title>

			<v-card-text class="card_text tw-flex-grow tw-overflow-hidden">
				<div class="tw-overflow-y-auto tw-w-full">
					<table class="layer-names-table tw-w-full tw-table-fixed">
						<thead class="tw-bg-gray-100">
							<tr>
								<th class="text-center tw-py-2">
									Nom
								</th>
								<th class="text-center tw-py-2">
									Numéro
								</th>
								<th class="text-center tw-py-2">
									Couleur
								</th>
								<th class="text-center tw-py-2">
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="(layerName, index) in sortedLayerNames"
							:key="index"
							>
								<td class="text-center">
									{{ layerName.name }}
								</td>
								<td class="text-center">
									{{ index + 1 }}
								</td>
								<td class="text-center">
									<v-color-picker
									v-model="layerName.color"
									hide-canvas
									flat
									dot-size="20"
									/>
								</td>
								<td class="text-center">
									<v-btn
									icon
									@click="moveLayerUp(index)"
									:disabled="index === 0"
									>
										<v-icon>mdi-chevron-up</v-icon>
									</v-btn>
									<v-btn
									icon
									@click="moveLayerDown(index)"
									:disabled="index === sortedLayerNames.length - 1"
									>
										<v-icon>mdi-chevron-down</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</v-card-text>
			
			<v-card-actions class="tw-sticky tw-bottom-0 tw-bg-white tw-py-4 tw-flex tw-justify-between">
				<ButtonSlot
				_theme="light-gray"
				@click="closePopin()"
				>
					Fermer
				</ButtonSlot>
				<ButtonSlot @click="updateLayerNames()">
					Valider
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>
  
<script>
export default {
	name: "StructuralParametersModal",
	props: [
		"campaign", "uniqueLayerNames", "isOpen"
	],
	data(){
		return {};
	},
	computed: {
		sortedLayerNames(){
			return [...this.uniqueLayerNames].sort((a, b) => {
				if(a.number === null) return 1;
				if(b.number === null) return -1;
				return a.number - b.number;
			});
		}
	},
	watch: {
		isOpen(){
			if(true === this.isOpen){
				this.initializeLayerColors();
			}
		}
	},
	methods: {
		updateLayerNames(){
			this.sortedLayerNames.forEach((layerName, index) => {
				if(!layerName.color){
					this.$set(this.sortedLayerNames[index], "color", "#FFFFFF");
				}
			});

			const updatedLayerNames = this.sortedLayerNames.map(layerName => ({
				...layerName,
				color: layerName.color
			}));
			this.$api.layerNames.updateByCampaign(this.campaign.id, updatedLayerNames);

			this.closePopin();
		},
		closePopin(){
			this.$emit("closePopin");
		},
		moveLayerUp(index){
			if(index > 0){
				const temp = this.sortedLayerNames[index];
				this.$set(this.sortedLayerNames, index, this.sortedLayerNames[index - 1]);
				this.$set(this.sortedLayerNames, index - 1, temp);
				this.reassignNumbers();
			}
		},
		moveLayerDown(index){
			if(index < this.sortedLayerNames.length - 1){
				const temp = this.sortedLayerNames[index];
				this.$set(this.sortedLayerNames, index, this.sortedLayerNames[index + 1]);
				this.$set(this.sortedLayerNames, index + 1, temp);
				this.reassignNumbers();
			}
		},
		reassignNumbers(){
			this.sortedLayerNames.forEach((layer, index) => {
				layer.number = index + 1;
			});
		},
		initializeLayerColors(){
			this.uniqueLayerNames.forEach(layerName => {
				if(null === layerName.color){
					this.$set(this.uniqueLayerNames[index], "color", "#FFFFFF");
				}
			});
		}
	},
	mounted(){
		this.reassignNumbers();
		this.initializeLayerColors();
	}
};
</script>


<style lang="scss">
@import "@/assets/scss/_extends.scss";

.structuralParametersModal {
  @extend %popinView;

  .v-card {
    width: 60%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

  .layer-names-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    th, td {
      padding: 8px;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #f5f5f5;
      font-weight: bold;
    }

    td {
      vertical-align: middle;
    }
  }
}
</style>

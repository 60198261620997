<template>
	<div>
		<div class="tw-flex tw-flex-row">
			<div class="tw-flex tw-flex-row tw-gap-[10px]">
				<v-select
				:items="directionSelect"
				v-model="direction"
				label="Choix du sens"
				outlined
				class="tw-w-[300px] tw-flex"
				hide-details
				dense
				/>
  
				<v-select
				:items="isDataLoaded ? transformLanes(getLanesForDirection()) : []"
				v-model="selectedLane"
				label="Voie"
				outlined
				class="tw-w-[300px] tw-flex"
				hide-details
				dense
				/>
			</div>
			<div class="tw-flex tw-ml-auto tw-items-center">
				<ButtonSlot @click="displayParameters = true">
					Paramètres
				</ButtonSlot>
			</div>
		</div>
  
		<div class="tw-overflow-x-auto tw-overflow-y-hidden tw-w-full tw-flex">
			<div class="tw-flex tw-flex-col tw-overflow-x-visible">
				<v-simple-table
				id="scrollTable"
				class="tw-mt-[10px] tw-w-full"
				style="table-layout: fixed; overflow-x: visible;"
				>
					<WaitingSlot
					v-if="!isDataLoaded"
					class="tw-h-full"
					/>

					<template
					v-if="isDataLoaded"
					v-slot:default
					>
						<thead>
							<tr>
								<th
								rowspan="4"
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 100px; max-width: 100px;"
								>
									Carotte
								</th>
        
								<th
								rowspan="1"
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 160px; max-width: 160px;"
								>
									Carotte
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'header'"
								colspan="1"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none; font-size: 20px;"
								>
									{{ core.displayNamePrefix.name + core.displayName }}
								</th>
							</tr>

							<tr>
								<th
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 160px; max-width: 160px;"
								>
									PR
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'pr'"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none;"
								>
									{{ core.landmark + ' + ' + core.abscissa }}
								</th>
							</tr>

							<tr>
								<th
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 160px; max-width: 160px;"
								>
									Voie
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'lane'"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none;"
								>
									{{ core.lane !== null ? core.lane : 'Sans voie' }}
								</th>
							</tr>

							<tr>
								<th
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 160px; max-width: 160px;"
								>
									Position
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'position'"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none;"
								>
									{{ $t(core.damage) }}
								</th>
							</tr>
						</thead>
	
						<tbody>
							<tr
							v-for="(layerName, index) in sortedLayerNames"
							:key="index+'layer'"
							>
								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px;"
								:style="{backgroundColor: layerName.materialCategory.initialism === 'MTLH' ? 'grey' : 'black'}"
								/>

								<td
								class="tw-text-center tw-font-bold"
								:style="{ backgroundColor: layerName.color ? layerName.color : 'transparent', minWidth: '160px', maxWidth: '160px' }"
								>
									{{ layerName.name }}
								</td>

								<td
								v-for="(core, coreIndex) in filteredCores"
								:key="coreIndex+'layer-thickness'"
								class="tw-text-center"
								style="width: auto; max-width: none;"
								:style="getBackgroundColor(core, layerName.name)"
								>
									<span v-if="hasLayerWithName(core, layerName.name)">
										{{ getLayerThickness(core, layerName.name) + ' mm' }}
									</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<v-simple-table
				id="scrollTable2"
				class="tw-mt-[10px] tw-w-full"
				style="table-layout: fixed; overflow-x: visible;"
				>
					<WaitingSlot
					v-if="!isDataLoaded"
					class="tw-h-full"
					/>

					<template
					v-if="isDataLoaded"
					v-slot:default
					>
						<tbody>
							<tr>
								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px; width: 100px"
								>
									Epaisseur d'EB (supérieur)
								</td>

								<td
								class="tw-text-center"
								style="min-width: 160px; max-width: 160px; width: 160px"
								>
									<!-- Alignement avec le tableau du dessus -->
								</td>

								<td
								v-for="(core, coreIndex) in filteredCores"
								:key="coreIndex + 'eb-thickness'"
								class="tw-text-center"
								style="width: auto; max-width: none;"
								>
									<v-text-field
									outlined
									dense
									type="text"
									v-model="core.ebThickness"
									hide-details
									class="tw-w-full"
									/>
								</td>
							</tr>

							<tr>
								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px; width: 100px"
								>
									Epaisseur de MTLH
								</td>

								<td
								class="tw-text-center"
								style="min-width: 160px; max-width: 160px; width: 160px"
								>
									<!-- Alignement avec le tableau du dessus -->
								</td>

								<td
								v-for="(core, coreIndex) in filteredCores"
								:key="coreIndex + 'mtlh-thickness'"
								class="tw-text-center"
								style="width: auto; max-width: none;"
								>
									<v-text-field
									outlined
									dense
									type="text"
									v-model="core.mtlhThickness"
									hide-details
									class="tw-w-full"
									/>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<ButtonSlot
				v-if="isDataLoaded"
				class="tw-mt-[10px]"
				@click="uploadThickness()"
				>
					Valider les épaisseurs
				</ButtonSlot>

				<div>
					<StackedBarChart
					v-if="isDataLoaded"
					:campaign="campaign"
					:cores="filteredCores"
					:uniqueLayerNames="uniqueLayerNames"
					/>
				</div>

				<div>
					<CorePicturesDiv
					v-if="isDataLoaded"
					:cores="filteredCores"
					/>
				</div>
			</div>
		</div>

		<StructuralParametersModal
		v-if="displayParameters"
		:campaign="campaign"
		:uniqueLayerNames="uniqueLayerNames"
		:isOpen="displayParameters"
		@closePopin="displayParameters = false"
		/>
	</div>
</template>

<script>
import StructuralParametersModal from "./StructuralParameters.modal.vue";
import StackedBarChart from "./StackedBarChart.vue";
import CorePicturesDiv from "./CorePicturesDiv.vue";

export default {
	components: {
		StructuralParametersModal,
		StackedBarChart,
		CorePicturesDiv
	},
	props: ["campaign"],
	data(){
		return {
			cores: [],
			isDataLoaded: false,
			uniqueLanes: [],
			uniqueLayerNames: [],
			direction: "Sens 1",
			selectedLane: null,
			headers: [
				"Carotte",
				"PR",
				"Voie",
				"Position"
			],
			displayParameters: false,
			maxThickness: 0,
			directionSelect: []
		};
	},
	computed: {
		filteredCores(){
			const direction = this.direction === "Sens 1" ? 1 : this.direction === "Sens 2" ? 2 : "Tous";

			return this.cores.filter(core => {
				// Gestion du filtre de la direction
				const isDirectionMatch = direction === "Tous" || core.direction === direction;

				// Gestion du filtre des voies
				const isLaneMatch = 
            this.selectedLane === null || 
            this.selectedLane === "Tous" || 
            core.lane === this.selectedLane || 
            (core.lane === null && this.selectedLane === "Sans voie");

				// Gestion du filtre de statut
				const isStatusValid = core.status !== "empty" && core.status !== "waiting";

				return isDirectionMatch && isLaneMatch && isStatusValid;
			});
		},



		sortedLayerNames(){
			const filteredLayerNames = this.uniqueLayerNames.filter(layerName => {
				return this.filteredCores.some(core => 
					core.layersAndInterfaces.filter(e => e.type === "layer").some(layer => 
						layer.layerName &&
						layer.layerName.name === layerName.name &&
						this.matchesDirectionAndLane(core)
					)
				);
			});

			return filteredLayerNames.sort((a, b) => {
				const aIsMTLH = a.materialCategory && a.materialCategory.initialism === "MTLH";
				const bIsMTLH = b.materialCategory && b.materialCategory.initialism === "MTLH";

				if(aIsMTLH && !bIsMTLH) return 1;
				if(!aIsMTLH && bIsMTLH) return -1;

				if(a.number === null) return 1;
				if(b.number === null) return -1;
				return a.number - b.number;
			});
		}
	},
	watch: {
		filteredCores(){
			this.filteredCores.forEach(core => {
				if(core.ebThickness === null){
					const ebLayers = core.layersAndInterfaces.filter(
						layer => layer.type === "layer" && layer.materialCategory?.initialism === "MB"
					);
					const ebThickness = ebLayers.reduce((sum, layer) => sum + (layer.thickness || 0), 0);

					this.$set(core, "ebThickness", ebThickness);
				}
				if(core.mtlhThickness === null){
					const mtlhLayers = core.layersAndInterfaces.filter(
						layer => layer.type === "layer" && layer.materialCategory?.initialism === "MTLH"
					);
					const mtlhThickness = mtlhLayers.reduce((sum, layer) => sum + (layer.thickness || 0), 0);
				
					this.$set(core, "mtlhThickness", mtlhThickness);
				}
			
				
			});
		},
		direction(newDirection){
			const uniqueLanes = this.extractUniqueLanes();
			if(newDirection === "Sens 1"){
				this.selectedLane = uniqueLanes.direction1.length > 0 ? uniqueLanes.direction1[0] : null;
			}
			else if(newDirection === "Sens 2"){
				this.selectedLane = uniqueLanes.direction2.length > 0 ? uniqueLanes.direction2[0] : null;
			}
			else {
				this.selectedLane = null;
			}
		}
	},

	methods: {
		getLanesForDirection(){
			const lanes = this.extractUniqueLanes();

			if(this.direction === "Tous"){
				// Retourne toutes les voies si la direction est "Tous"
				return lanes.all;
			}
			else if(this.direction === "Sens 1"){
				// Retourne les voies de direction 1
				return lanes.direction1;
			}
			else if(this.direction === "Sens 2"){
				// Retourne les voies de direction 2
				return lanes.direction2;
			}
			return [];
		},
		getBackgroundColor(core, layerName){
			if(this.hasLayerWithName(core, layerName)){
				const layer = core.layersAndInterfaces.find(layer => layer.layerName && layer.layerName.name === layerName);

				switch (layer.state){
					case "healthy_smooth":
					case "TTBO":
					case "CTBO":
					case "TBON":
					case "CBON":
						return "background-color: green";
					case "crushed":
					case "TMED":
					case "fragmented":
					case "cracked_smooth":
					case "cracked_fragments_ripped_off":
					case "CMED":
					case "FEUIL":
						return "background-color: orange";
						
					case "mediocre_smooth":
					case "mediocre_fragments_ripped_off":
					case "FRAC":
					case "TFAI":
					case "CFAI":
					case "MADI":
						return "background-color: red";
				}
			}
		},
		uploadThickness(){
			this.filteredCores.forEach(core => {
				if(core.ebThickness !== null || core.mtlhThickness !== null){
					this.$api.cores.patch({ebThickness: core.ebThickness, id: core.id, mtlhThickness: core.mtlhThickness});
				}
			});
		},
		matchesDirectionAndLane(core){
			const directionMatch = this.direction === "Tous" || 
                           (this.direction === "Sens 1" ? core.direction === 1 : core.direction === 2);
			const laneMatch = this.selectedLane === null || this.selectedLane === "Sans voie" || core.lane === this.selectedLane;
			return directionMatch && laneMatch;
		},

		setNumberOfLayers(){
			let max = 0;
			let numberOfLayers;
			this.cores.forEach(core =>  {
				numberOfLayers = core.layersAndInterfaces.filter(e => e.type === "layer").length;
				if(max < numberOfLayers){
					max = numberOfLayers;
				}
			});
			this.layersByCores = max + 1;
		},
		extractUniqueLanes(){
			const lanesByDirection = {
				direction1: new Set(),
				direction2: new Set()
			};

			this.cores.forEach(core => {
				if(core.direction === 1){
					lanesByDirection.direction1.add(core.lane);
				}
				else if(core.direction === 2){
					lanesByDirection.direction2.add(core.lane);
				}
			});

			// Ajouter l'option "Tous" à chaque direction
			const allLanes = new Set([...lanesByDirection.direction1, ...lanesByDirection.direction2]);
			const allLanesArray = Array.from(allLanes);

			// Retourner les voies uniques pour chaque direction et "Tous"
			return {
				direction1: ["Tous", ...Array.from(lanesByDirection.direction1)],
				direction2: ["Tous", ...Array.from(lanesByDirection.direction2)],
				all: ["Tous", ...allLanesArray]
			};
		},

		extractUniqueLayerNames(){
			const uniqueLayerNames = new Map();

			this.cores.forEach(core => {
				core.layersAndInterfaces.forEach(layer => {
					if(layer.layerName && layer.layerName.id && layer.layerName.name){
						if(!uniqueLayerNames.has(layer.layerName.id)){
							uniqueLayerNames.set(layer.layerName.id, {
								...layer.layerName,
								materialCategory: layer.materialCategory || null,
								color: layer.layerName.color || "#CCC" // Ajoute une couleur par défaut si elle n'existe pas
							});
						}
					}
				});
			});

			return Array.from(uniqueLayerNames.values());
		},
		transformLanes(lanes){
			return lanes.map(lane => lane === null ? "Sans voie" : lane);
		},
		hasLayerWithName(core, layerName){
			return core.layersAndInterfaces.some(layer => layer.layerName && layer.layerName.name === layerName);
		},
		getLayerThickness(core, layerName){
			const layer = core.layersAndInterfaces.find(layer => layer.layerName && layer.layerName.name === layerName);
			return layer ? layer.thickness : null;
		},
		getBarStyle(core, layerName, layerIndex){
			const thickness = this.getLayerThickness(core, layerName) || 0;
			const totalHeight = this.maxThickness;
			const percentageHeight = (thickness / totalHeight) * 100;
			const offset = this.getAccumulatedHeight(core, layerIndex);

			return {
				backgroundColor: this.getLayerColor(layerName),
				height: `${percentageHeight}%`,
				bottom: `${offset}%`,
				position: "absolute",
				width: "100%"
			};
		},
		getAccumulatedHeight(core, layerIndex){
			return this.sortedLayerNames.slice(0, layerIndex).reduce((acc, layer) => {
				const thickness = this.getLayerThickness(core, layer.name) || 0;
				return acc + (thickness / this.maxThickness) * 100;
			}, 0);
		},
		getLayerColor(layerName){
			const layer = this.uniqueLayerNames.find(l => l.name === layerName);
			return layer && layer.color ? layer.color : "#ccc";
		},
		setMaxThickness(){
			this.maxThickness = Math.max(...this.cores.map(core => 
				core.layersAndInterfaces.reduce((acc, layer) => acc + (layer.thickness || 0), 0)
			));
		},
		evaluateCoreDirections(){
			const directions = new Set(this.cores.map(core => core.direction));

			if(directions.size === 1){
				// Si tous les cores ont la même direction
				const uniqueDirection = [...directions][0];
				if(uniqueDirection === 1){
					this.directionSelect = ["Sens 1"];
					this.direction = "Sens 1";
				}
				else if(uniqueDirection === 2){
					this.directionSelect = ["Sens 2"];
					this.direction = "Sens 2";
				}
			}
			else if(directions.size === 2){
				this.directionSelect = [
					 "Tous", "Sens 1", "Sens 2"
				];
				this.direction = "Tous";
			}
		}
	},
	mounted(){
		this.$api.cores.findByCampaign(this.campaign.id, true).then(data => {
			this.cores = data;
			this.setNumberOfLayers();
			this.uniqueLanes = this.extractUniqueLanes();
			this.selectedLane = this.uniqueLanes.direction1[0];
			this.uniqueLayerNames = this.extractUniqueLayerNames();
			this.isDataLoaded = true;
			this.evaluateCoreDirections();
	  });
	}
};
</script>

<style scoped>
.bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
}
th,
td {
  border: solid 1px lightgrey;
  min-width: 180px;
}
th {
  background-color: #2b0803;
  color: white !important;
  font-size: 24px;
}
tr > td:first-child {
  background-color: #ebe7e7;
  font-weight: bold;
}
table {
  border-radius: 4px !important;
  table-layout: fixed;
  width: 100%;
}

</style>
